import { Controller } from "@hotwired/stimulus"
import { debounce } from 'underscore';
import $ from "jquery";

export default class extends Controller {
  static targets = [ 'listLink', 'priceFilter', 'search', 'productModal', 'collapse' ];

  $productModal = null;

  initialize() {
    this.onSearch = debounce(this.onSearch, 300).bind(this);
  }

  connect() {
    this.$productModal = $(this.productModalTarget).modal({
      show: false,
      focus: false,
    });
  }

  openProductModal = e => {
    const productColorId = $(e.target).closest('a').attr('data-product-color-id')
    const list = $(this.productModalTargets)
    for (let element of list) {
      if ($(element).attr('data-product-color-id') === productColorId) {
        $(element).modal('show')
      }
    }
  }

  onSearch = () => this.onFilter()

  onFilter = () => {
    const prodCatIds = $('input.product-category:checked').map((idx, el) => $(el).val()).get();
    const brands = $('input.brand:checked').map((idx, el) => $(el).val()).get();
    const bodyTypes = $('input.body-type:checked').map((idx, el) => $(el).val()).get();
    const priceFilter = this.priceFilterTarget.value;
    const q = this.searchTarget.value;
    const url = $('a#get-product-cards').attr('href');
    $.ajax({
      method: 'GET',
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
          remote: window.location.href.includes('/steps/design'),
          category: prodCatIds,
          brands,
          body_types: bodyTypes,
          sort: priceFilter,
          q: q && q.length ? q : null
      },
      dataType: 'script',
      success: () => {
        $('a#get-product-cards').trigger('ajax:success');
        $('#product-modal').trigger('darko:filtered');
      }
    })
  }

  listLinkClick(e) {
    const $el = $(e.currentTarget);
    $('#product-select li.list-group-item').removeClass('list-group-item-action active');
    $el.parent('li.list-group-item').addClass('list-group-item-action active');
    $el.parent().parent().find('.list-group-item input[type="checkbox"]').prop('checked', true);
  }
}